var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-contact-info",
      "title": _vm.$t('reservation.contact'),
      "title-class": "text-airline",
      "centered": "",
      "footer-class": "d-flex justify-content-center",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "font-weight-bolder mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.agencyInfo')) + " ")]), _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', {
    staticClass: "border",
    attrs: {
      "header-bg-variant": "light-info",
      "header-class": "py-50 font-weight-bold",
      "body-class": "py-1 px-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.phoneNumber')) + " ")])];
      },
      proxy: true
    }])
  }, [['AK', 'F1', 'TH'].includes(_vm.dataReservation.source) ? _c('b-row', {
    staticClass: "mx-0"
  }, _vm._l(_vm.contactDataToAdd.phone, function (phone, index) {
    return _c('b-col', {
      key: index
    }, [_c('b-form-group', {
      staticClass: "font-weight-bold"
    }, [_c('b-form-input', {
      attrs: {
        "disabled": ""
      },
      model: {
        value: _vm.contactDataToAdd.phone[index].address,
        callback: function callback($$v) {
          _vm.$set(_vm.contactDataToAdd.phone[index], "address", $$v);
        },
        expression: "contactDataToAdd.phone[index].address"
      }
    })], 1), _c('i', {
      staticClass: "font-small-4 text-danger"
    }, [_vm._v("*" + _vm._s(_vm.$t('reservation.cannotChangePhone')))])], 1);
  }), 1) : _c('div', [_c('b-row', {
    staticClass: "mx-0"
  }, _vm._l(_vm.contactDataToAdd.phone, function (phone, index) {
    return _c('b-col', {
      key: index,
      staticClass: "my-25 px-0",
      attrs: {
        "cols": "12"
      }
    }, [!_vm.isEditPhone ? _c('div', {
      staticClass: "d-flex"
    }, [_c('b-form-input', {
      attrs: {
        "id": "contact-phone-input-".concat(index),
        "disabled": _vm.contactDataToAdd.phone[index] !== ''
      },
      model: {
        value: _vm.contactDataToAdd.phone[index].address,
        callback: function callback($$v) {
          _vm.$set(_vm.contactDataToAdd.phone[index], "address", $$v);
        },
        expression: "contactDataToAdd.phone[index].address"
      }
    }), _c('b-dropdown', {
      attrs: {
        "variant": "link",
        "toggle-class": "p-0",
        "dropright": "",
        "no-caret": "",
        "right": _vm.$store.state.appConfig.isRTL
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_vm.canEdit ? _c('b-button', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(40, 199, 111, 0.15)',
              expression: "'rgba(40, 199, 111, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "btn-icon rounded",
            attrs: {
              "variant": "flat-info ml-25"
            }
          }, [_vm._v(" Sửa ")]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": _vm.toggleEditPhone
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EditIcon"
      }
    }), _c('span', {
      staticClass: "align-middle ml-50"
    }, [_vm._v(_vm._s(_vm.$t('Edit')))])], 1), _c('b-dropdown-item', {
      attrs: {
        "disabled": _vm.source !== 'VN' || _vm.contactDataToAdd.phone.length < 2
      },
      on: {
        "click": function click($event) {
          return _vm.removePhoneHandle(_vm.contactDataToAdd.phone[index]);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "variant": "danger",
        "icon": "TrashIcon"
      }
    }), _c('span', {
      staticClass: "align-middle ml-50"
    }, [_vm._v(_vm._s(_vm.$t('delete')))])], 1)], 1)], 1) : _c('div', {
      staticClass: "d-flex"
    }, [_c('validation-provider', {
      staticClass: "w-100",
      attrs: {
        "name": "Phone",
        "rules": "required|phoneNumber"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-input', {
            attrs: {
              "id": "contact-phone-input-".concat(index),
              "state": _vm.getValidationState(validationContext) === false ? false : null,
              "lazy-formatter": "",
              "formatter": _vm.trimInput,
              "debounce": 300,
              "placeholder": _vm.contactDataToAdd.phone[index].address
            },
            model: {
              value: _vm.blankDataPhone[index],
              callback: function callback($$v) {
                _vm.$set(_vm.blankDataPhone, index, $$v);
              },
              expression: "blankDataPhone[index]"
            }
          }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
        }
      }], null, true)
    }), _c('b-button', {
      staticClass: "ml-50 px-50 text-nowrap",
      attrs: {
        "id": "contact-phone-done-".concat(index),
        "variant": "flat-success"
      },
      on: {
        "click": function click($event) {
          return _vm.handleEditPhone(_vm.contactDataToAdd.phone[index], _vm.blankDataPhone[index], index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "CheckIcon"
      }
    })], 1), _c('b-button', {
      staticClass: "ml-50 px-50 text-nowrap",
      attrs: {
        "id": "contact-phone-cancel-".concat(index),
        "variant": "flat-danger"
      },
      on: {
        "click": _vm.toggleEditPhone
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)]);
  }), 1)], 1)], 1), _c('b-card', {
    staticClass: "border",
    attrs: {
      "header-bg-variant": "light-info",
      "header-class": "py-50 font-weight-bold",
      "body-class": "py-1 px-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', [_vm._v(" Email ")])];
      },
      proxy: true
    }])
  }, [['AK', 'F1', 'TH'].includes(_vm.dataReservation.source) ? _c('b-row', {
    staticClass: "mx-0"
  }, _vm._l(_vm.contactDataToAdd.email, function (email, index) {
    return _c('b-col', {
      key: index
    }, [_c('b-form-group', {
      staticClass: "font-weight-bold"
    }, [_c('b-form-input', {
      staticClass: "text-truncate",
      attrs: {
        "disabled": true
      },
      model: {
        value: _vm.contactDataToAdd.email[index].address,
        callback: function callback($$v) {
          _vm.$set(_vm.contactDataToAdd.email[index], "address", $$v);
        },
        expression: "contactDataToAdd.email[index].address"
      }
    })], 1), _c('i', {
      staticClass: "font-small-4 text-danger"
    }, [_vm._v("*" + _vm._s(_vm.$t('reservation.cannotChangeEmail')))])], 1);
  }), 1) : _c('div', [_c('b-row', {
    staticClass: "mx-0"
  }, _vm._l(_vm.contactDataToAdd.email, function (email, index) {
    return _c('b-col', {
      key: index,
      staticClass: "p-0 my-25",
      attrs: {
        "cols": "12"
      }
    }, [!_vm.isEditEmail ? _c('div', {
      staticClass: "d-flex"
    }, [_c('b-form-input', {
      staticClass: "text-truncate",
      attrs: {
        "id": "contact-email-".concat(index),
        "disabled": _vm.contactDataToAdd.email[index] !== ''
      },
      model: {
        value: _vm.contactDataToAdd.email[index].address,
        callback: function callback($$v) {
          _vm.$set(_vm.contactDataToAdd.email[index], "address", $$v);
        },
        expression: "contactDataToAdd.email[index].address"
      }
    }), _c('b-dropdown', {
      attrs: {
        "id": "contact-email-".concat(index),
        "variant": "link",
        "toggle-class": "p-0",
        "dropright": "",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_vm.canEdit ? _c('b-button', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(40, 199, 111, 0.15)',
              expression: "'rgba(40, 199, 111, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "btn-icon rounded",
            attrs: {
              "variant": "flat-info ml-25"
            }
          }, [_vm._v(" Sửa ")]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": _vm.toggleEditEmail
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EditIcon"
      }
    }), _c('span', {
      staticClass: "align-middle ml-50"
    }, [_vm._v(_vm._s(_vm.$t('Edit')))])], 1), _c('b-dropdown-item', {
      attrs: {
        "disabled": _vm.source !== 'VN' || _vm.contactDataToAdd.email.length < 2
      },
      on: {
        "click": function click($event) {
          return _vm.removeEmailHandle(_vm.contactDataToAdd.email[index]);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "variant": "danger",
        "icon": "TrashIcon"
      }
    }), _c('span', {
      staticClass: "align-middle ml-50"
    }, [_vm._v(_vm._s(_vm.$t('delete')))])], 1)], 1)], 1) : _c('div', {
      staticClass: "d-flex"
    }, [_c('validation-provider', {
      staticClass: "w-100",
      attrs: {
        "name": "Email",
        "rules": "required|email"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-input', {
            attrs: {
              "id": "contact-email-".concat(index),
              "lazy-formatter": "",
              "formatter": _vm.trimInput,
              "debounce": 300,
              "state": _vm.getValidationState(validationContext) === false ? false : null,
              "placeholder": _vm.contactDataToAdd.email[index].address
            },
            model: {
              value: _vm.blankDataEmail[index],
              callback: function callback($$v) {
                _vm.$set(_vm.blankDataEmail, index, $$v);
              },
              expression: "blankDataEmail[index]"
            }
          }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
        }
      }], null, true)
    }), _c('b-button', {
      staticClass: "ml-50 px-50 text-nowrap",
      attrs: {
        "id": "contact-email-done-".concat(index),
        "variant": "flat-success"
      },
      on: {
        "click": function click($event) {
          return _vm.handleEditEmail(_vm.contactDataToAdd.email[index], _vm.blankDataEmail[index], index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "CheckIcon"
      }
    })], 1), _c('b-button', {
      staticClass: "ml-50 px-50 text-nowrap",
      attrs: {
        "id": "contact-email-cancel-".concat(index),
        "variant": "flat-danger"
      },
      on: {
        "click": _vm.toggleEditEmail
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)]);
  }), 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-reservation-modify-add-phone",
      "title": "Thêm số điện thoại",
      "centered": ""
    },
    on: {
      "show": _vm.resetModalAddPhone,
      "hidden": _vm.resetModalAddPhone
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.handleOkModalAddPhone
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.confirm')))])])];
      }
    }])
  }, [_c('b-form', {
    ref: "form-add-phone",
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleSubmitModalAddPhone($event);
      }
    }
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": "Phone",
      "rules": "required|phoneNumber"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label": "Số điện thoại",
            "label-for": "phone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "phone",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "debounce": 300,
            "placeholder": "Nhập vào số điện thoại"
          },
          model: {
            value: _vm.phoneToAdd,
            callback: function callback($$v) {
              _vm.phoneToAdd = $$v;
            },
            expression: "phoneToAdd"
          }
        })], 1), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-reservation-modify-add-email",
      "title": "Thêm email",
      "centered": ""
    },
    on: {
      "show": _vm.resetModalAddEmail,
      "hidden": _vm.resetModalAddEmail
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref3) {
        var close = _ref3.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.handleOkModalAddEmail
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.confirm')))])])];
      }
    }])
  }, [_c('b-form', {
    ref: "form-add-phone",
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleSubmitModalAddPhone($event);
      }
    }
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "email",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "debounce": 300,
            "placeholder": "Nhập vào email"
          },
          model: {
            value: _vm.emailToAdd,
            callback: function callback($$v) {
              _vm.emailToAdd = $$v;
            },
            expression: "emailToAdd"
          }
        })], 1), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }